// This logic was created to lazy load infiniteslide images.
// It checks the position of the images relative to the viewport using getBoundingClientRect().
// Intersection Observer doesn't work well with transformed images on iOS.
// In order to use it you need to do the following:
// 1. Add class .custom-lozad and attribute data-src to images;
// 2. Add class .custom-lozad to Settings -> WP Rocket -> MEDIA -> Excluded images or iframes;
// 3. Trigger custom-lozad:update on window after slider initialization;

$( function() {
	let images = [];
	let requestId = null;
	let windowIsLoaded = false;

	function loadImages() {
		if ( !images.length ) {
			return;
		}

		const windowWidth = document.documentElement.clientWidth;
		const windowHeight = document.documentElement.clientHeight;

		images = images.filter( image => {
			const { left, top } = image.getBoundingClientRect();

			if ( top < windowHeight && left < windowWidth ) {
				image.src = image.dataset.src;
				image.removeAttribute( "data-src" );
			} else {
				return true;
			}
		} );

		requestId = requestAnimationFrame( loadImages );
	}

	$( window ).on( "load", function() {
		setTimeout( function() {
			windowIsLoaded = true;
			findImagesAndStartLoading();
		}, 0 );
	} );

	$( window ).on( "custom-lozad:update", function() {
		if ( windowIsLoaded ) {
			findImagesAndStartLoading();
		}
	} );

	function findImagesAndStartLoading() {
		images = Array.from( document.querySelectorAll( ".custom-lozad[data-src]" ) );
		cancelAnimationFrame( requestId );
		requestId = requestAnimationFrame( loadImages );
	}
} );