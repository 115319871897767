$( function() {
	$( document ).ready( function() {
		$( ".js-read-more" ).each( function() {
			const readMoreBtn = $( this );
			let readMoreContent = readMoreBtn.siblings( ".js-collapse" );

			if ( !readMoreBtn.siblings( ".js-collapse" ).length ) {
				readMoreContent = readMoreBtn.parent().siblings( ".js-collapse" );
			}

			readMoreBtn.on( "click", function() {
				const btn = $( this );
				if ( btn.hasClass( "active" ) ) {
					btn.removeClass( "active" );
					btn.find( ".button__text" ).text( btn.data( "text" ) );
					readMoreContent.slideUp( 300 );
				} else {
					btn.addClass( "active" );
					btn.find( ".button__text" ).text( btn.data( "hide-text" ) );
					readMoreContent.slideDown( 300 );
				}
			} );
		} );
	} );
} );

