/*
 * Modules
 */
import "bootstrap/js/dist/modal";
import "./modules/hide-preloader";
import "./modules/gtm-event";
import "./modules/init-video-popups";
import "./modules/read-more";
import ninjaForms from "./modules/ninja-forms";
import "./modules/custom-lozad";

$( document ).ready( function() {
	ninjaForms();
	( async function customScrollbar() {
		await import( "./scrollbar.min.js" );
	} )( jQuery );

	if ( $( ".scrollbar-outer" ).length ) {
		setTimeout( function() {
			$( ".scrollbar-outer:not(.scroll-content)" ).scrollbar( {
				ignoreMobile: true,
				ignoreOverlay: true,
				// disableBodyScroll: true,
				passive: true,
			} );
		}, 1000 );
	}

	$( "a, button, input[type=checkbox], input[type=radio], input[type=button], input[type=submit]" ).on( "mouseup, mouseleave", function() {
		$( this ).blur();
	} );

	var lazyVideo = $( "#lazy-video" );
	var source = lazyVideo.find( "source[data-src]" );

	if ( source.length ) {
		var src = source.attr( "data-src" );
		source.attr( "src", src );
		source.removeAttr( "data-src" );
		lazyVideo.get( 0 ).load();
	}

	$( ".modal" ).on( "show.bs.modal", function( event ) {
		let button = $( event.relatedTarget );
		let modal = $( this );

		modal.on( "hidden.bs.modal", function() {
			setTimeout( function blur() {
				button.blur();
			}, 50 );
		} );
	} );
} );
