export default () => {
	try {
		// check active section in viewport
		if ( $( ".js-listen-scroll-section" ).length ) {
			const $sections = [
				...document.querySelectorAll( ".js-listen-scroll-section" ),
			];

			const intersectionObserver = new IntersectionObserver(
				( entries ) => {
					entries.forEach( entry => {
						if ( entry.intersectionRatio > 0 ) {
							entry.target.classList.add( "show-viewport" );
						}
					} );
				}
			);

			$sections.forEach( ( section ) => {
				intersectionObserver.observe( section );
			} );
		}
	} catch ( error ) {
		console.error( error, "error animations" );
	}
};
