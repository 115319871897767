const responsePopup = $( ".form-response" );
const successWrapper = responsePopup.find( ".form-response-wrapper.success" );
const errorWrapper = responsePopup.find( ".form-response-wrapper.error" );
const successDesc = successWrapper.find( ".description" );
const successBtn = successWrapper.find( ".success-button" );
const errorBtn = errorWrapper.find( ".error-button" );
const closeModal = $( ".close-wrapper" );
const closeModalResponseSuccess = successWrapper.find( ".close-wrapper" );
const closeModalResponseError = errorWrapper.find( ".close-wrapper" );

const ninjaForms = () => {
	$( document ).on( "nfFormSubmitResponse", function( event, response ) {
		let $input = $( ".nf-form-content input:not([type='checkbox'])" );
		let $select = $( ".nf-form-content select" );
		let $textarea = $( ".nf-form-content textarea" );

		$input.focusin( function() {
			$( this ).closest( ".field-wrap" ).addClass( "focus" );
		} );

		$input.focusout( function() {
			if ( $( this ).val() === "" ) {
				$( this ).closest( ".field-wrap" ).removeClass( "focus" );
			}
		} );

		$select.focusin( function() {
			$( this ).closest( ".field-wrap" ).addClass( "focus" );
		} );

		$select.focusout( function() {
			if ( $( this ).val() === "" ) {
				$( this ).closest( ".field-wrap" ).removeClass( "focus" );
			}
		} );

		$textarea.focusin( function() {
			$( this ).closest( ".field-wrap" ).addClass( "focus" );
		} );

		$textarea.focusout( function() {
			if ( $( this ).val() === "" ) {
				$( this ).closest( ".field-wrap" ).removeClass( "focus" );
			}
		} );

		const responseData = response.response.data;
		const { redirect, success_message: successMessage } = responseData.actions;
		if ( !redirect ) {
			if ( response.response.errors.length > 0 ) {
				responsePopup.addClass( "active" );
				successWrapper.hide();
				errorWrapper.fadeIn( 500 );
				disableScroll();
			} else {
				const forms = $( ".nf-form-cont" );
				let hash = "#thank-you";

				const formId = responseData.form_id;
				const currentForm = forms.filter( `#nf-form-${formId}-cont` );

				const classList = Array.from( currentForm[0].classList );
				const matchingClass = classList.find( ( item ) => item.startsWith( "js-form_" ) );

				if ( matchingClass ) {
					const formName = matchingClass.split( "_" )[1];
					hash += `-${formName}`;
				}

				history.replaceState( null, document.title, location.pathname + location.search + hash );

				if ( successMessage ) {
					successDesc.html( successMessage );
				}

				responsePopup.addClass( "active" );
				errorWrapper.hide();
				successWrapper.fadeIn( 500 );
				disableScroll();
			}
		}
	} );

	errorBtn.on( "click", function() {
		hideResponsePopup();
	} );

	closeModalResponseError.on( "click", function() {
		hideResponsePopup();
	} );

	closeModalResponseSuccess.on( "click", function() {
		closeModal.off( "click" ).trigger( "click" ).on( "click", closeModalClickHandler );
	} );

	successBtn.on( "click", function() {
		closeModal.off( "click" ).trigger( "click" ).on( "click", closeModalClickHandler );
	} );

	function closeModalClickHandler() {
		hideResponsePopup();
	}

	closeModal.on( "click", closeModalClickHandler );

	$( document ).keydown( function( e ) {
		const code = e.keyCode || e.which;
		if ( code === 27 ) {
			hideResponsePopup();
		}
	} );

	$( document ).click( function( e ) {
		if ( $( e.target ).hasClass( "form-response" ) ) {
			hideResponsePopup();
		}
	} );
};

function hideResponsePopup() {
	responsePopup.removeClass( "active" );
	successWrapper.fadeOut( {
		duration: 500,
		complete: () => {
			successDesc.html( `<p>${successDesc.data( "default-desc" )}</p>` );
		},
	} );
	errorWrapper.fadeOut( 500 );
	enableScroll();

	history.replaceState( null, document.title, location.pathname + location.search );
}

function disableScroll() {
	const scrollTop = $( window ).scrollTop();
	$( "body" ).data( "scroll-position", scrollTop );

	$( "body" ).css( {
		"position": "fixed",
		"width": "100%",
		"top": -scrollTop
	} );

	$( "html" ).css( "overflow", "hidden" );
}

function enableScroll() {
	const scrollTop = $( "body" ).data( "scroll-position" );
	$( "body" ).css( {
		"position": "",
		"width": "",
		"top": ""
	} );

	$( "html" ).css( "overflow", "" );

	$( window ).scrollTop( scrollTop );
}

export default ninjaForms;
